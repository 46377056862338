.disabled {
    display: none;
}

.edit-source_wrapper {
    background-color: white;
    font-size: 14px;
}

.edit-active_image {
    padding: 2% 10%;
}

    .edit-active_image img {
        border: 1px solid;
    }

.edit-move_buttons_wrapper {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 100%;
}

.edit-move_buttons
{
    /*display: flex;
    justify-content: space-between;*/
}

.edit-move_buttons_row {
    /*flex-basis: 100%;*/
    display: flex;
}

.edit-move_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.edit-move_button 
{
    
}

.edit-edit_source_wrapper {
    margin: .75em 3em;
}

.edit-input_source_wrapper {
    display: inline-block;
    width: 50%;
}

    .edit-input_source_wrapper label {
        font-size: 1em;
    }

    .edit-input_source_wrapper input {
        width: 3em;
        font-size: 1em;
        margin: 0 .3em;
        text-align: right;
    }

.edit-row {
    margin: .5em;
}
    .edit-row button {
        width: 40%;
        display: block;
        margin: auto;
    }