.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .login-wrapper .error-message
    {
        text-align: center;
        color: red;
    }
    .login-wrapper .submit-button_container {
        padding-top: 10px;
    }

    .login-wrapper .submit-button {
        margin: auto;
        display: block;
    }

    .login-wrapper form {
        box-shadow: none;
        border: none;
        padding-bottom: 10px;
    }

    .login-wrapper .form-group
    {
        margin-bottom: 10px;
    }

.login-header
{
    text-align: center;
}

.loading-container {
    width: 30%;
    max-width: 250px;
    margin: auto;
}

.google-signin
{
    padding-left: 10px;
}