.footer-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-container {
    font-size: 16px;
    border-top: 1px solid;
    border-color: #dedede;
    margin-top: 20px;
    display: flex;
}

.footer-container, .footer-container a, .footer-container a:hover {
    color: #696969;
}

.footer-logo_wrapper {
    flex-basis: 40%;
    padding-top: 30px;
}

.footer-logo {
    width: 40%;
    display: inline-block;
    max-width: 195px;
}

.footer-contact {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    margin-top: 10px;
}

.footer-social_wrapper
{

}

.social-icon {
    
}

.social-link {
    display: inline-block;
    vertical-align: top;
    border-radius: 20px;
    margin: 10px 15px 10px -8px;
    width: 36px;
    height: 36px;
    padding: 3px;
}

.social-link:hover {
    background-color: #656464;
    border-radius: 30px;
}

.footer-info {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    /* height: 200px; */
    /* border-radius: 6px; */
}

.footer-copyright {
    color: #ACF8F4;
    background-color: #34b4d1;
    position: relative;
    height: 2.75em;
}

    .footer-copyright > div {
        padding: 1em 30px;
        font-size: .75em;
        position: absolute;
        /*right: 30px;*/
    }


.footer-column {
    /*margin: auto;
    text-align: center;
    width: 50%;
    display: inline-block;
    vertical-align: top;*/
    flex-grow: 1;
    max-width: 50%;
}

.footer-header {
    font-size: 1em;
    margin-top: 10px;
}


.footer-link_wrapper {
    /*margin-bottom: .5em;*/
}

.footer-link {
    font-size: .75em;
    /*color: white;*/
}

.footer-link:hover
{
    /*color: white;*/
}

@media (max-width: 575px)
{
    .footer-container {
        flex-direction: column-reverse;
    }

    .footer-logo_wrapper {
        border-top: 1px solid;
        border-color: #dedede;
    }
}