#terms-page {
    padding: 0px 5px;
}

    #terms-page h3 {
        text-align: center;
    }

#terms-page ol {
        counter-reset: item;
        padding-left: 0px;
    }


#terms-page li {
    display: block;
    margin-bottom: 1rem;
    font-size: 14px;
}

#terms-page li:before
{
    content: counters(item, ".") " ";
    counter-increment: item;
}

.terms-section_container {
    padding: 15px 0px;
}

    .terms-section_container h5 {
        font-size: 1rem;
    }

.terms-section_container p
{
    font-size: 14px;
}

.terms-note ul {
    list-style-type: disc;
    margin-left: 50px;
    font-size: 14px;
}