.error-message {
    color: #fff;
    /*position: absolute;*/
    display: flex;
    justify-content: center;
    padding: 0 15px 10px 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

    .error-message svg {
        margin-right: 10px;
    }

