.loading-container {
    width: 30%;
    max-width: 250px;
    min-width: 130px;
    margin: auto;
}

.loading-message {
    font-size: .9em;
    text-align: center;
    color: #267789;
}

.progress-bar-override {
    height: 5px;
    width: 50%;
    margin: auto;
}

.bg-bar_color {
    background-color: #37C2E1;
}
