p {
    font-size: 14px;
}

.banner-container {
    position: absolute;
    width: 100%;
    /* padding-top: 25%; */
    left: 0;
}
.info-container {
    position: relative;
    width: 100%;
    padding-top: 26vw;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
}

.inside {
    width: 100%;
    height: 100%;
}

.logo {
    width: 12%;
    position: relative;
    display: inline-block;
}

.info {
    position: relative;
    display: inline-block;
    width: 88%;
}

.info h4
{
    font-size: 1.5em;
}

.info p
{
    font-size: 1em;
    margin-bottom: .5rem;
}

.text-gray {
    color: #757575;
}

.profile {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 12%;
    height: 100%;
    margin-right: 3%;
}

.profile-image {
    border-radius: 50%;
    width: 100%;
    max-width: 60px;
    margin: auto;
    display: block;
}

.center {
    text-align: center;
}

@media (max-width: 575px)
{
    .info-container {
        margin: 10px 0px;
        font-size: 12px;
    }

    .logo {
        display:none;
        width: 20%;
        height: 20%;
        vertical-align: top;
    }

    .info {
        width: 100%;
        margin-right: 10px;
    }

    .info h4
    {
        padding-top: 10px
    }

    .profile
    {
        display: none;
    }
}