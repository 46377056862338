.cart-icon {
    margin-left: 20px;
    position: relative;
}
@media (max-width: 575px)
{
    .cart-icon {
        /*width: 35px;*/
        margin: 0px 10px 0px 0px;
    }
}

    .cart-icon:hover {
        border-radius: 20px;
    }

.cart-icon-badge svg {
    width: 24px;
    height: 24px;
}

.num-icon {
    position: absolute;
    top: -4px;
    right: -3px;
    border: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 15px;
    padding: 3px 6px 3px 6px;
    line-height: 1;
    font-size: 11px;
    font-weight: bold;
    background-color: #FAA077;
}
