body {
    /*font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;*/
    height: 100vh;
    width: 100vw;
}

/*html {
    background-color: #6772e5;
    font-size: 16px;
    font-family: Roboto, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    height: 100%;
}*/

/*div.checkout-wrapper input,
button,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}
*/
select.payment option
{
    color: black;
}
    /* Buttons and links */
    /*button.payment {
        background: #5469d4;
        font-family: Arial, sans-serif;
        color: #ffffff;
        border-radius: 0 0 4px 4px;
        border: 0;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
    }*/

        button.payment:hover {
            filter: contrast(115%);
        }

        button.payment:disabled {
            opacity: 0.5;
            cursor: default;
        }


.checkout-wrapper {
    /*width: 500px;
    height: 400px;*/
    position: relative;
    /*padding-top: 25px;*/
}

@keyframes fade {
    from {
        opacity: 0;
        transform: scale3D(0.95, 0.95, 0.95);
    }

    to {
        opacity: 1;
        transform: scale3D(1, 1, 1);
    }
}

.form {
    animation: fade 200ms ease-out;
    background-color: #ffffff;
}

form.payment {
    /*width: 30vw;*/
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    border: none;
    padding: 40px;
    font-size: 16px;
}

.form fieldset {
    margin: 0 15px 20px;
}
.form-group {
    padding: 0;
    border-style: none;
    background-color: #ffffff;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #cbdadd;
    border-radius: 4px;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #acf8f445;
    justify-content: space-between;
}

    .form-row:first-child {
        border-top: none;
    }

.form-row-label {
    min-width: 100px;
    padding: 11px 0;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-size: 1em;
    font-weight: bold
}

.form-row-input:-webkit-autofill {
    -webkit-text-fill-color: #808080;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

@keyframes void-animation-out {
    0%, to {
        opacity: 1;
    }
}

.form-row-input {
    font-size: 1em;
    width: 85%;
    padding: 11px 15px 11px 15px;
    color: #808080;
    background-color: transparent;
    border: none;
    box-shadow: none;
    animation: 1ms void-animation-out;
}

.form-row-input-checkbox
{
    margin-right: 10px;
}

.form-row-input:hover {
    box-shadow: none;
    border: none;
}

    .form-row input.form-row-input:hover
    {
        box-shadow: none;
        border:none;
    }

.form-row-input::placeholder {
    color: #80808055;
}

.form-terms {
    overflow: auto;
    height: 200px;
    background-color: white;
    margin: 10px 15px;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.back-button {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border-style: none;
    padding: 5px 0px 0px 10px;
    border: none;
    box-shadow: none;
}

    .back-button:hover {
        border: none;
        box-shadow: none;
    }


.back-button:focus
{
    outline: none;
}

    .back-button svg {
        background-color: #f0f0f0;
        border-radius: 9px;
    }
        .back-button svg:hover {
            filter: contrast(115%);
        }

.payment-button {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 10px 15px 0;
    background-color: #f6a4eb;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.submit-button:active {
    background-color: #d782d9;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
    transform: scale(0.99);
}

.submit-button:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
}

.result-message {
    line-height: 22px;
    font-size: 16px;
}

    .result-message a {
        color: rgb(89, 111, 214);
        font-weight: 600;
        text-decoration: none;
    }

.hidden {
    display: none;
}

#card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
}

#card-element {

}

#payment-request-button {
    margin-bottom: 32px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

    .spinner:before,
    .spinner:after {
        position: absolute;
        content: "";
    }

    .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: #0366d6;
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: #0366d6;
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
    }

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 575px) {
    form.payment
    {
        padding: 20px;
        font-size: 14px;
    }
}
