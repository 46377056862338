ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.order-container
{
    font-size: 16px;
}

.listing-item {
    max-width: 252px;
    padding-top: 5px;
    margin-right: 20px;
    display: inline-block;
    width: 40%;
}


a.order {
    display: inline-block;
    width:100%;
    height:100%;
    color: black;
}

a.text-black {
    color:black;
}

a.text-black > * 
{
    color: black;
}

a.no-decoration:hover {
    text-decoration: none;   
}

.hover {
    box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);
}

.description {
    font-size: 1em;
    font-weight: 400;
}

.item-image {
    width: 100%;
    height: 100%;
}

.item-image h6
{
    font-size: 1em;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.disabled {
    display: none;
}

@media (max-width: 575px)
{
    .listing-item {
        width: 48%;
        margin-right: 5px;
    }

    .order-container
    {
        font-size: 12px;
    }
}