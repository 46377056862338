
.image-self 
{
    background-color: white;
    cursor: zoom-out;
    width: 100%;
    height: 100%;
   
}

.center-align 
{
    text-align: center;
    vertical-align: middle;
}

.black 
{
    background: #0b0b0b;
}
