.error_popup-container
{
    padding-left: 5px;
    background-color: white;
}
.red {
    color: red;
}

.text-align-center
{
    text-align: center;
}
