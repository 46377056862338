.alert_box-container {
    /*text-align: center;*/
    background-color: white;
}

.center-align 
{
    text-align: center;
}

.left-align 
{
    text-align: left;
}

.right-align 
{
    text-align: right;
}

.error 
{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-children {
    padding: 10px;
    font-size: 1em;
}

.buttons-container
{
    padding: 10px 10px 10px 10px;
}

.buttons-container .button
{
    margin-right: 10px;
}