/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

select, input, form, button, .button, textarea, .selectable, .box-wrapper {
    /*margin-right: 10px;*/
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
    font-size: 16px;
    border-radius: 6px;
    border-color: rgba(34, 34, 34, 0.15);
    border: 1px solid #ddd;
    /*padding-left: 5px;*/
}
    input[type="text"]:hover, input[type="password"]:hover, textarea:hover, select:hover, button:hover, .button:hover, .selectable:hover {
        /*border-color: rgba(34, 34, 34, 0.5);*/
        box-shadow: 0 1px 12px 0 rgb(34 34 34 / 20%);
        /*box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);*/
    }

    input[disabled]:hover, select[disabled]:hover, textarea[disabled]:hover button[disabled]:hover, .selectable[disabled]:hover {
        box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
        cursor: default;
    }

    select, input, textarea {
        padding-left: 5px;
    }

select, input, button, .selectable {
    cursor: pointer;
}

input[type="text"], input[type="password"], textarea
{
    cursor: text;
}

button:not([class="selected"]), button:focus:not([class="selected"]), input:not([type="checkbox"]), select, textarea:focus:not([class="selected"]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    /*border-style: none;*/
}

button, .button {
    display: inline-block;
    font-weight: 400;
    
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    /*transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;*/
}

    .button:not([disabled]), .button:not([disabled]) {
        color: #212529;
    }

        .button:not([disabled]):hover {
            color: #212529;
            text-decoration: none;
        }


.button-two {
    color: #fff;
    background-color: #37C2E1;
    border-color: #37C2E1;
    margin-right: 10px;
}

    .button-two:not([disabled]):hover {
        color: #fff;
        background-color: #34b4d1;
        border-color: #34b4d1;
    }

.button-primary {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 10px 15px 0;
    background-color: #0366d6;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

    .button-primary:not([disabled]):hover {
        filter: contrast(115%);
    }

.button-three {
    background-color: transparent;
    border: none;
    box-shadow:none;
    /*height: 40px;*/
    /* color: #222222; */
    font-weight: 500;
    /* padding-left: 0px; */
    margin-left: -5px;
}

    .button-three:hover {
        background-color: rgba(34, 34, 34, 0.5);
        box-shadow: 0 1px 12px 0 rgb(34 34 34 / 20%);
        border-radius: 12px;
    }

.subtext {
    font-size: .7em;
}

.border {
    border-radius: 6px;
    border-color: rgba(34, 34, 34, 0.15);
    border: 1px solid #ddd;
}

.container-box {
    padding: 20px;
    margin: 10px 0;
    background-color: #f7f7f7;
}