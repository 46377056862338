.customize-container {
    display: inline-block;
    position: relative;
    width: 100%;
}
.panel {
    display: inline-block;
    width: 50%;
}
.panel-right {
    float: right;
    padding-bottom: 100px;
}
.panel-left {
   float: left;
   height: 100%;
   position: absolute;
    padding-right: 10px;
}

.not-allowed {
    cursor: not-allowed;
}

#tabelLabel {
    font-weight: 400;
    font-size:30px;
}

.price {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    margin: 10px 0px 10px 0px;
    display: inline-block;
}

.design-wrapper {
    background-color: #f1f1f1;
    /*border: 1px solid #ddd;
    border-radius: 4px;*/
    padding: 0;
    margin-bottom: 20px;
}

.design-title {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    margin: 0;
    font-weight: 700;
    color: #333;
    text-align: center;
    /*width: 100%;
    float: left;
    clear: both;*/
}

.design-item {
    padding: 10px 15px 15px;
    display: inline-block;
    margin-left: 15px;
}

.group-item {
    padding: 8px 15px;
}

.input-wrapper {
    display: block;
}

.item-label {
    font-weight: 700;
    display: block;
    margin-bottom: 5px;
}

.input-box {
    /*border: 1px solid #ddd;*/
    width: 100%;
    height: 100%;
    position: relative;
}

    .input-box::after {
        content: "";
        background-image: url(https://artofjoycdn.blob.core.windows.net/images-dev/dropdown%20icon.svg);
        width: 24px;
        height: 24px;
        pointer-events: none;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    .input-box select{
        width: 100%;
        height: 100%;
        padding-right: 36px;
    }

.inline_text {
    padding-right: 10px;
    padding-left: 2px;
    display: inline-block;
}

.inline_label{
    width: 54px;
}

.inline_dropdown {
    width: calc(100% - 54px);
    padding-right: 0px;
}

.font-select
{
    padding-top: 10px;
}

input.inline_text
{
    margin-right: 10px;
}

.part-item {
    
    display: inline-block;
    margin: 10px 10px 2px 2px;
    /*box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);*/
    background-color: #fff;
    max-width: 216px;
    min-width: 90px;
    width: 90px;
}
.item {
    margin-bottom: 15px;
}

.selected {
    outline: 1px solid #0984e3;
}

.disabled {
    display: none;
}

.active-image_outer-wrapper {
    padding-right: 25px;
}

.active-image {
    width: 100%;
    position: relative;
    /*outline: 1px solid;*/
    /*background-color: black;*/
}
.active-image_inner-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /*padding: 0% 10% 0% 10%;*/
}

.active-image-self
{
    width: 100%;
    height: 100%;
}

.zoom {
    cursor: zoom-in;
}

.active-image-loading
{
    text-align: center;
}

.red
{
    color: red;
}

.quote
{
    width: 216px;
}

@media (max-width: 575px)
{
    .panel {
        width: 100%;
    }
    .panel-left {
        position: unset;
        margin: auto;
        padding: 0px 20px;
    }

    .panel-right
    {
        padding: 15px 15px 0px 0px;
    }
    /*.part-item{
        min-width: 54px;
    }
*/
    .group-item {
        padding: 15px 0px 0px 0px;
    }
    .design-item
    {
        margin-left: 0px;
    }

    .part-item {
        margin: 10px 2px 2px 2px;
    }

    .quote
    {
        margin: 10px auto;
        display: block;
    }
}
