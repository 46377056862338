.default 
{
    background-color: white;
}

.fill-screen {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8;
}

.popup-outer_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1043;
}

.popup-container {
    width: 100vh;
    height: 100%;
    margin: auto;
    position: relative;
}


.popup-outline {
    width: 100%;
    height: 100%;
    border: 1px solid;
}

.popup-outer_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 5% 14% 5% 14%;
}


.popup-self {
    background-color: transparent;
    overflow: auto;
    max-height: 100%;
    border-radius: 6px;
}

.popup-root {
    
}

.close_wrapper {
    /*float: right;*/
    position: absolute;
    right: 14%;
    /*top: 5%;*/
    z-index: 1043;
}

.close-button {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1;
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    opacity: .6;
}

    .close-button:hover {
        opacity: 1;
        box-shadow: none;
    }

.close-button:active
{
    border: 0px;
}

.disabled
{
    display: none;
}

@media (max-width: 575px)
{
    .popup-container{
        width: 100%;
        font-size: 14px;
    }

    .popup-outer_wrapper
    {
        padding: 5% 0 0 0;
    }

    .close_wrapper
    {
        right: 0;
    }

}

@media (max-width: 400px)
{
    .popup-container{
        font-size: 12px;
    }
}

@media (max-width: 300px) {
    .popup-container {
        font-size: 10px;
    }
}