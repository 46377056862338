.button-text {
    border: none;
    box-shadow: none;
    font-weight: 100;
    font-size: 1rem;
    line-height: 1;
    padding: 5px;
    color: #007bff;
}

    .button-text:hover {
        box-shadow: none;
        background-color: #c5c5c5;
    }

