.wrapper {
    position: relative; 
    min-height: 100vh
}

.content
{
    padding-bottom: 350px;
}

@media (max-width: 575px)
{
    .content
    {
        padding-bottom: 500px;
    }
}
