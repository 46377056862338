#about-page{
    padding-right: 10px;
}

    #about-page h3, #about-page p {
        text-align: center;
        padding-top: 15px;
        margin-bottom: .5rem;
    }

.about-page_images {
    display: flex;
    width: 100%;
    height: 250px;
    justify-content: center;
    padding-bottom: 15px;
}

@media (max-width: 575px)
{
    .about-page_images
    {
        display: none;
    }
}
.about-page_images img {
    height: 100%;
    padding: 0px 8px;
}
