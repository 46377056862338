.form-price {
    /*box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%), inset 0 1px 0 rgb(0 0 0 / 8%);*/
    /*border-radius: 4px;*/
    /* display: inline; */
    /* position: absolute; */
    background-color: #f1f1f1;
    margin-left: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    /* right: 0; */
    /* top: 43px; */
    width: 32%;
    display: inline-block;
    vertical-align: top;
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
}

.text-display {
    padding: 3px 10px;
    position: relative;
}


.price-display {
    
}

    .price-display span {
        width: 50%;
        display: inline-block;
    }

    .price-display span:first-child
    {
        text-align: left;
    }

    .price-display span:nth-child(2)
    {
        text-align: right;
    }

.checkbox {
    position: absolute;
    right: 12px;
    bottom: 8px;
}

.proceed-button {
    margin: auto;
    margin-top: 10px;
    width: calc(100% - 30px);
    display: block;
}

.form-text
{
    text-align: center;
}

.shopping-item {
    padding: 10px 0px 20px 0px;
    /*position: relative;
    display: inline-block;
    width: 66.6666666666%;*/
}

.item-image_wrapper {
    /*width: auto;*/
    display: inline-block;
    vertical-align: top;
    max-width: 252px;
}

.item-image {
    width: 100%;
    height: 100%;
}

.item-text {
    font-size: 13px;
    padding: 5px 0px 5px 0px;
}

.item-button {
    background-color: transparent;
    border: none;
    height: 40px;
    /* color: #222222; */
    font-weight: 500;
    /* padding-left: 0px; */
    margin-left: -5px;
}

    .item-button:hover {
        background-color: rgba(34, 34, 34, 0.5);
        box-shadow: 0 1px 12px 0 rgb(34 34 34 / 20%);
        border-radius: 12px;
    }

.item-price {
    /*display: inline;
    position: absolute;
    right: 0;
    top: 10px;*/
}

.item-select {
    margin-right: 10px;
    /*box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
    font-size: 16px;*/
    height: 48px;
    width: 60px;
    display: inline-block;
    /*border-radius: 6px;
    border-color: rgba(34, 34, 34, 0.15);
    cursor: pointer;*/
}
    

.item-info {
    display: inline-block;
    /* position: absolute; */
    /* top: 10px; */
    /* left: 10px; */
    padding-left: 10px;
    width: calc(100% - 254px);
    vertical-align: top;
}

.item-info-left
{
    display: inline-block;
    width: 60%;
    vertical-align: top;
    margin-right: 20px;
}

.item-info-right 
{
    display: inline-block;
    width: 33.3333333%;
    vertical-align: top;
}

.item-description{
    width: 100%;
    display: inline-block;
}

.right {
    right: 10px;
}

.left
{
    left: 10px;
}

.cart-container {
    /*position: relative;*/
    display: inline-block;
    width: 66.6666666%;
}

.cart-heading
{

}

@media (max-width: 620px) 
{
    .item-image_wrapper {
        max-width: 40%;
    }

    .item-info
    {
        width: 60%;
    }
    .cart-container, .shopping-item, .item-info-right, .item-info-left
    {
        width: 100%
    }

    .form-price {
        width: auto;
        display: block;
        margin-right: 15px;
        margin-left: 0px;
    }

    .item-description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .item-select {
        width: 60px;
        height: 35px;
    }
}